<script setup>
import { ref, watch } from 'vue';
import { validateEmail } from '@/util/helper';

const props = defineProps({
  option: {
    type: Object,
    required: true
  },
  isSingleOption: {
    type: Boolean,
    default: false
  },
  isEditingText: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:modelValue', 'set-value', 'set-editing', 'cancel-editing']);

const title = ref('');
const emptySubtitle = ref('');
const inputValue = ref('');
const previousInputValue = ref(0);
const editMode = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref(undefined);

const validatePhoneNumber = phone => {
  const phoneRegex = /^\+\d{1,3}\s?\d{4,14}$/;
  return phoneRegex.test(phone);
};

const validateInitialValue = () => {
  if (props.option.value === '' || props.option.value === null) {
    return;
  }

  if (props.option.type === 'email') {
    if (!validateEmail(props.option.value)) {
      errorMessage.value = 'Invalid email address provided initially';
      showErrorMessage.value = true;
    }
  } else if (props.option.type === 'phone') {
    if (!validatePhoneNumber(props.option.value)) {
      errorMessage.value = 'Invalid phone number. It must start with "+" and include country code.';
      showErrorMessage.value = true;
    }
  }
};

watch(
  () => props.isEditingText,
  newValue => {
    if (newValue && editMode.value) {
      cancelEditMode();
    }
  }
);

const setValues = () => {
  inputValue.value = props.option?.value;
  switch (props.option.type) {
    case 'phone':
      title.value = 'Phone no.:';
      emptySubtitle.value = 'add mobile phone number';
      break;
    case 'email':
      title.value = 'E-mail:';
      emptySubtitle.value = 'add E-mail address';
      break;
  }
  validateInitialValue();
};

const changeInputValue = event => {
  if (props.option.type === 'email' && errorMessage) {
    if (validateEmail(event.target.innerHTML)) {
      errorMessage.value = undefined;
    } else {
      errorMessage.value = 'Invalid email address';
    }
  } else if (props.option.type === 'phone' && errorMessage) {
    if (validatePhoneNumber(event.target.innerHTML)) {
      errorMessage.value = undefined;
    } else {
      errorMessage.value = 'Invalid phone number. It must start with "+" and include country code.';
    }
  }
  inputValue.value = event.target.innerHTML;
};

const openEditMode = () => {
  previousInputValue.value = inputValue.value;
  emit('set-editing');
  editMode.value = true;
};

const confirmEditMode = () => {
  showErrorMessage.value = true;
  if (
    props.option.type === 'email' &&
    !validateEmail(inputValue.value) &&
    inputValue.value !== '' &&
    inputValue.value !== null
  ) {
    errorMessage.value = 'Invalid email address';
    return;
  } else if (
    props.option.type === 'phone' &&
    !validatePhoneNumber(inputValue.value) &&
    inputValue.value !== '' &&
    inputValue.value !== null
  ) {
    errorMessage.value = 'Invalid phone number. It must start with "+" and include country code.';
    return;
  }
  emit('set-value', { type: props.option.type, value: inputValue.value });
  emit('cancel-editing');
  showErrorMessage.value = false;
  editMode.value = false;
};

const cancelEditMode = () => {
  showErrorMessage.value = false;
  inputValue.value = previousInputValue.value;
  if (errorMessage.value) {
    errorMessage.value = undefined;
  }
  emit('cancel-editing');
  editMode.value = false;
};

setValues();
</script>

<template>
  <div class="flex items-center mb-2.5">
    <input
      v-if="!isSingleOption"
      :data-test-id="'patient-form-input-option-' + option.type"
      :id="option.type + '-insurer-form'"
      :value="option.type"
      name="option-type-insurer-form"
      type="radio"
      class="h-12 w-12 rounded-full cursor-pointer mr-7 custom-radio"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <div class="items-center" :class="{ 'edit-border p-5': editMode }">
      <label :for="option.type + '-insurer-form'" class="inline-block">
        <span class="font-bold">{{ title }}</span>
        <span
          v-if="editMode"
          :data-test-id="'patient-form-change-add-' + option.type"
          class="ml-1 border-b border-black whitespace-pre-wrap"
          :class="[inputValue ? 'pr-2' : 'pr-20']"
          role="textbox"
          contenteditable
          @click.prevent
          @input="changeInputValue"
        >
          {{ inputValue }}
        </span>
        <span v-else-if="inputValue" class="ml-1"> [{{ inputValue }}] </span>
        <span v-else class="ml-1 underline cursor-pointer" @click.prevent="openEditMode">{{ emptySubtitle }}</span>
      </label>
      <div v-if="editMode" class="inline-block">
        <i
          :data-test-id="'patient-form-submit-' + option.type"
          class="fa-solid fa-check ml-2.5 cursor-pointer text-2xl"
          style="color: #006600"
          @click="confirmEditMode"
        />
        <i
          :data-test-id="'patient-form-cancel-' + option.type"
          class="fa-solid fa-xmark ml-2.5 cursor-pointer text-2xl"
          style="color: #ad0000"
          @click="cancelEditMode"
        />
      </div>
      <i
        v-else-if="!isEditingText"
        :data-test-id="'patient-form-edit-' + option.type"
        class="fa-solid fa-pen ml-2.5 cursor-pointer"
        @click="openEditMode"
      />
      <span v-if="errorMessage && showErrorMessage" class="block error-message">
        <i class="fa-solid fa-triangle-exclamation mx-1"></i>
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type='radio'] {
  accent-color: #029fd6;
}

span[role='textbox']:focus {
  outline: none !important;
}

.edit-border {
  border: 1px solid #d9d9d9;
}

.error-message {
  font-size: 14px;
  color: #ac0606;
}

.custom-radio {
  @apply appearance-none border-2 rounded-full h-10 w-10 sm:h-12 sm:w-12 cursor-pointer;
}

.custom-radio:checked {
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #00a7e1;
}

.custom-radio:checked::before {
  content: '';
  display: block;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #00a7e1;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
