<template>
  <div :id="inputId" class="mri-procedures-list-section" v-if="dataLoaded">
    <div id="mri-treatment" v-for="(procedure, index) in procedures" :key="index" class="col-12 mt-5 li">
      <div v-if="index > 0" style="margin-top: 15px; border: 3px solid rgb(238, 238, 238)"></div>
      <div class="col-12 items-center">
        <div class="col-4 pr-5 mt-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Radiology Procedure Code:</label>
          </div>
          <div class="mt-1">
            <ProcedureCodeTypeahead
              :id="inputId + '_proc_code' + index"
              v-model="procedure.procedure.procedure_code"
              placeholder="Search procedure code"
              :min-input-length="1"
              @selected-procedure="selected_procedure(index, $event)"
              @cleared-procedure="cleared_procedure(index)"
              :categories="categories"
              :dataValue="dataValue"
            />
            <textarea
              class="mt-1"
              v-if="procedure.procedure.procedure_code"
              :id="inputId + '_proc_desc' + index"
              v-model="procedure.procedure.procedure_code_description"
              readonly
              rows="3"
              disabled
            />
          </div>
        </div>

        <div class="custom-test-type col-2 pr-5 mt-5">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Test Type:</label>
          </div>
          <div class="mt-1">
            <select
              :id="inputId + '_test_type' + index"
              v-model="procedure.test_type"
              :disabled="isTextBoxDisabled"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option v-for="labtest in testTypes" :key="labtest.name" :value="labtest.value">
                {{ labtest.name }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="enableDate === true" class="col-12 mt-4">
          <label class="block text-sm font-medium text-gray-700">Date of Service</label>
          <date-picker
            class="mt-1"
            id="inputId + '_date_of_service' + index"
            v-model:value="procedure.date_of_service"
            value-type="YYYY-MM-DD"
            format="DD-MM-YYYY"
            :disabled="isTextBoxDisabled"
            :append-to-body="false"
          />
          <!-- <DateQuestion :id="inputId + '_date_of_service' + index" v-model="procedure.dateOfService" question="Date of Service"/> -->
        </div>

        <div class="custom-remove-section mb-8">
          <div
            v-if="!isTextBoxDisabled"
            :id="inputId + '_remove_mri' + index"
            class="custom-click"
            @click="removeTreatment(index, procedures)"
          >
            <i class="fas fa-minus-circle text-red-600"></i> Remove Entry
          </div>
          <div v-else :id="inputId + '_remove_mri' + index" class="custom-click">
            <i class="fas fa-minus-circle text-gray-600"></i> Remove Entry
          </div>
        </div>
      </div>
      <section v-if="enableClinicalIndicators === true">
        <div
          v-if="
            (clinicalIndicatorCodes[index] && clinicalIndicatorCodes[index].length > 0) ||
            procedure.procedure.clinical_indicator_code
          "
          class="col-12 mt-4"
        >
          <div class="col-4">
            <div class="custom-code-input">
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Clinical Indicator:</label>
              </div>
              <Typeahead
                :id="inputId + '_clinical_code' + index"
                v-model="procedure.procedure.clinical_indicator_code"
                :items="clinicalIndicatorCodes[index]"
                placeholder="Search clinical code"
                :min-input-length="1"
                :item-projection="showCIItem"
                :item-value="getCIItemValue"
                @selectItem="selectCIItem"
                @update:modelValue="check_if_matching($event, index)"
                :field="procedure"
              />
            </div>
          </div>
          <div class="col-8">
            <div class="custom-text-input">
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Clinical Description:</label>
              </div>
              <textarea
                :id="inputId + '_clinical_desc' + index"
                v-model="procedure.procedure.clinical_indicator_code_description"
                readonly
                rows="1"
                class="p-2 rounded"
                :disabled="isTextBoxDisabled"
              />
            </div>
          </div>
        </div>
      </section>

      <TextQuestion
        v-show="enableAnatomicalSite === true"
        v-model="procedure.anatomical_site"
        :id="inputId + '_anatomical_site' + index"
        question="Anatomical Site Examined"
        :rows="4"
        class="mb-3"
      />
      <TextQuestion
        v-show="enableClinicalInterpretation === true"
        v-model="procedure.clinical_interpretation"
        :id="inputId + '_clinical_interpretation' + index"
        question="Clinical Interpretation of the Scan/Diagnosis"
        :rows="4"
        class="mb-3"
      />
      <div v-show="enableFacilityName === true">
        <label class="block text-sm font-medium text-gray-700">Facility name, if performed elsewhere</label>
        <Typeahead
          :id="inputId + '_facility_name' + index"
          v-model="procedure.other_facility_name.name"
          :items="labFacilities"
          :min-input-length="1"
          :item-projection="showFacilityItem"
          :item-value="getFacilityItem"
          @selectItem="selectFacilityItem"
          :field="procedure"
          :fullW="true"
        />
      </div>

      <div class="custom-anaesthasia-input col-6" v-if="enableAnaesthesia === true">
        <EnumQuestion
          :id="inputId + '_anaesthesia' + index"
          v-model="procedure.anaesthesia.application"
          :options="anaesthesiaOptions"
          question="Anaesthesia"
        />

        <TextQuestion
          v-show="procedure.anaesthesia.application !== null && procedure.anaesthesia.application !== 'no_anaesthesia'"
          v-model="procedure.anaesthesia.reason"
          :id="inputId + '_anaesthesia_reason' + index"
          :rows="4"
          question="Reason for Anaesthesia"
        />
      </div>

      <!-- start -->
      <!-- <div v-if="enableConsultant === true" class="col-12 mt-5">

        <div class='col-6 pr-5 float-left'>
          <div class="flex justify-between">
            <label class="block text-sm font-medium text-gray-700">Name of Consultant in charge</label>
          </div>
          <div class="mt-1">
            <select v-model="procedure.consultant"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option :value="null"></option>
              <option v-for="(medic, index) in medics" :key='index' :value="medic">{{ medic.firstname }} {{ medic.lastname
              }}
              </option>
            </select>
          </div>
        </div>

      </div> -->
      <!-- end -->
    </div>
    <div class="col-6 mt-4">
      <div v-if="isTextBoxDisabled" :id="inputId + '_add_mri'" class="mt-3 custom-click text-gray-200">
        <i class="fas fa-plus-circle text-gray-200"></i> {{ add_another }}
      </div>
      <div
        v-if="!isTextBoxDisabled"
        :id="inputId + '_add_mri'"
        class="mt-3 custom-click cursor-pointer font-semibold"
        @click="addTreatment(procedures)"
      >
        <i class="fas fa-plus-circle text-green-600"></i> {{ add_another }}
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
  <!-- end -->
</template>

<script>
//import CustomDatePicker from '@/components/claim-forms/components/Datepicker'
// css
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import '@/assets/css/typeahead.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import { ProcedureCodeCategory } from 'enums';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';
//import DateQuestion from "@/components/form-fields/DateQuestion.vue";
import EnumQuestion from '@/components/claim-forms/components/EnumQuestion.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { mapState } from 'vuex';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import ProcedureCodeTypeahead from '@/components/form-fields/ProcedureCodeTypeahead.vue';
import { OpenAPIJSON } from 'api';

/**
 * Component for representing a list of procedures (treatments) applied to a patient.
 *
 * Properties:
 *   :treatments="sectionData.medicalInvestigations.radiologyMri"
 */

export default {
  name: 'MRIProcedures',
  components: {
    ProcedureCodeTypeahead,
    EnumQuestion,
    TextQuestion,
    //CustomDatePicker,
    Typeahead,
    //DateQuestion,
    DatePicker,
    ToastComponent
  },
  props: {
    modelValue: {},
    dataValue: {},
    defaultDate: { required: true },
    enableClinicalIndicators: { required: false, type: Boolean },
    enableAnatomicalSite: { required: false, type: Boolean },
    enableFacilityName: { required: false, type: Boolean },
    enableConsultant: { required: false, type: Boolean },
    enableDate: { required: false, type: Boolean },
    enableClinicalInterpretation: { required: false, type: Boolean },
    enableAnaesthesia: { required: false, type: Boolean, default: false },
    anaesthesiaOptions: { required: false, type: Array },
    ct: { required: false, type: Boolean, default: false },
    mri: { required: false, type: Boolean, default: false },
    petct: { required: false, type: Boolean, default: false },
    id: { type: String },
    insurerId: { type: Number },
    appointmentId: null,
    labFacilities: { type: Array },
    add_another: { type: String, default: 'Add another radiology treatment' }
  },
  data() {
    return {
      api: new OpenAPIJSON(),
      testTypes: [],
      formActive: false,
      picked: new Date(),
      sectionData: this.claim,
      sectionCompleted: false,
      procedureCodes: [],
      investigationIdx: -1,
      clinicalIndicatorCodes: {},
      medics: null,
      inputId: this.id || `mri_procedure_list${(Math.random() * 1000).toFixed()}`,
      dataLoaded: false,
      categories: []
    };
  },
  watch: {
    modelValue: {
      handler: function (newVal) {
        this.convertFacilityToName(newVal);
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    procedures: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    selected_procedure(index, item) {
      console.log('selected_procedure idx=', index, ' item', item);
      this.cleared_procedure(index);
      this.procedures[index].procedure.procedure_code = item.procedure_code;
      this.procedures[index].procedure.procedure_code_description = item.procedure_code_description;
      for (let cidx = 0; cidx < item.clinical_indicator_codes.length; cidx++) {
        this.clinicalIndicatorCodes[index].push({
          clinical_indicator_code: item.clinical_indicator_codes[cidx].ci_code,
          clinical_indicator_code_description: item.clinical_indicator_codes[cidx].ci_description
        });
      }
    },
    cleared_procedure(index) {
      this.procedures[index].procedure.procedure_code = '';
      this.procedures[index].procedure.procedure_code_description = '';
      this.procedures[index].procedure.clinical_indicator_code = '';
      this.procedures[index].procedure.clinical_indicator_code_description = '';
      this.clinicalIndicatorCodes[index] = [];
    },

    showCIItem(item) {
      //console.log("showCIItem item=", item)
      if (item !== null) {
        return item.clinical_indicator_code + ' ' + item.clinical_indicator_code_description;
      } else {
        return '';
      }
    },

    getCIItemValue(item) {
      console.log('getCIItemValue item=', item);
      if (item !== null) {
        return item.clinical_indicator_code;
      } else {
        return '';
      }
    },

    selectCIItem(item, field) {
      console.log('selectCIItem=', item, ' field=', field);
      field.procedure.clinical_indicator_code = item.clinical_indicator_code;
      field.procedure.clinical_indicator_code_description = item.clinical_indicator_code_description;
    },
    check_if_matching(item, index) {
      console.log('check_if_matching item=', item);
      for (let cidx = 0; cidx < this.clinicalIndicatorCodes[index]?.length; cidx++) {
        if (this.clinicalIndicatorCodes[index].clinical_indicator_code === item) {
          this.procedures[index].procedure.clinical_indicator_code_description =
            this.clinicalIndicatorCodes[index].procedure.clinical_indicator_code_description;
          console.log('check_if_matching (matched) item=', item);
          return;
        }
      }
      this.procedures[index].procedure.clinical_indicator_code_description = '';
    },
    addTreatment(treatments) {
      // Fake, negative diagnosis ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      let dos = treatments.length > 0 ? treatments[treatments.length - 1].date_of_service : this.defaultDate;
      let data = new Date(dos);
      let year = data.getUTCFullYear();
      let month = String(data.getUTCMonth() + 1).padStart(2, '0');
      let day = String(data.getUTCDate()).padStart(2, '0');
      dos = `${year}-${month}-${day}`;
      treatments.push({
        investigationId: this.investigationIdx,
        test_type: this.testTypes[0].value,
        other_facility_name: {
          name: '',
          type: 'name'
        },
        other_facility_tests: null,
        procedure: {
          procedure_code: '',
          procedure_code_description: null,
          clinical_indicator_code: null,
          clinical_indicator_code_description: null
        },
        date_of_service: dos,
        anatomical_site: null,
        anaesthesia: {
          anaesthesia: {
            dosage: null,
            drug_code: null,
            drug_id: null,
            drug_insurer_code_id: null,
            drug_name: null,
            is_branded: null,
            product_code: null,
            product_name: null
          },
          anaesthetist: null,
          application: null,
          reason: null
        },
        clinical_interpretation: null
      });
      this.investigationIdx -= 1;
    },
    removeTreatment(index, treatments) {
      const treatmentIndex = treatments[index];
      if (treatmentIndex) {
        treatments.splice(index, 1);
      }
    },
    convertFacilityToName(investigations) {
      if (investigations.length === 0) return;
      for (const investigation of investigations) {
        if (investigation.other_facility_name === null) {
          investigation.other_facility_name = {
            name: '',
            type: 'name'
          };
        }
        if (investigation.other_facility_name?.ro?.name) {
          investigation.other_facility_name.name = investigation.other_facility_name.ro.name;
        } else if (investigation.other_facility_name?.ro?.name === '') {
          investigation.other_facility_name.name = '';
        }
        if (investigation.other_facility_name?.type === 'id') {
          investigation.other_facility_name.type = 'name';
        }
        delete investigation.other_facility_name.ro;
        delete investigation.other_facility_name.id;
      }
    },
    showFacilityItem(item) {
      if (item.facility_name) {
        return item.facility_name;
      }
      return null;
    },
    getFacilityItem(item) {
      if (item.facility_name) {
        return item.facility_name;
      }
      return null;
    },
    selectFacilityItem(item, field) {
      field.other_facility_name.name = item.facility_name;
    }
  },
  async mounted() {
    this.dataLoaded = false;
    this.testTypes = [];
    if (this.ct) {
      this.testTypes.push({ name: 'CT', value: 'c_t' });
      this.categories.push(ProcedureCodeCategory.RADIOLOGY_CT);
    }
    if (this.mri) {
      this.testTypes.push({ name: 'MRI', value: 'm_r_i' });
      this.categories.push(ProcedureCodeCategory.RADIOLOGY_MRI);
    }
    if (this.petct) {
      this.testTypes.push({ name: 'PET-CT', value: 'p_e_t_c_t' });
      if (!this.mri) {
        this.categories.push(ProcedureCodeCategory.RADIOLOGY_MRI);
      }
    }
    this.categories.push(ProcedureCodeCategory.RADIOLOGY_NUCLEAR);
    this.categories.push(ProcedureCodeCategory.RADIOLOGY_NORMAL);
    this.convertFacilityToName(this.procedures);
    this.dataLoaded = true;
  }
};
</script>

<style scoped>
.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

textarea:disabled {
  background-color: #f3f3f3;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

select:disabled {
  background-color: #f3f3f3;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.custom-input:focus {
  outline: 1px solid #4338ca;
  outline: none;
}

.custom-test-type {
  width: 150px;
}

.custom-remove-section {
  float: right;
  width: 150px;
}
</style>
