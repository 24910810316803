<script setup lang="ts">
import { titleOptions } from 'utils';

import {
  BaseNumber,
  BaseSelect,
  BaseInput,
  BaseDateInput,
  BaseAddressInput,
  BasePhoneNumberInput,
  BaseRadioButton
} from 'ui';

import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  similarIllness: {
    type: Boolean,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="laya-policy-details-title" title="Policy Details" />
    <BaseNumber
      id="policy-no"
      name="policyNo"
      class="mt-6"
      label="Membership no"
      :max-length="10"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-no')"
    />
    <BaseSelect
      id="patient-title"
      name="patientTitle"
      class="mt-6 sm:mt-12"
      label="Title*"
      :options="titleOptions"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-title')"
    />
    <BaseInput
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />
    <BaseInput
      id="patient-forenames"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Forenames*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-forenames')"
    />
    <BaseDateInput name="birthDate" class="mt-6 sm:mt-12" label="Date of Birth*" :edit-mode="editMode" />
    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
    <BasePhoneNumberInput
      id="telephone"
      name="telephone"
      class="mt-6 sm:mt-12"
      label="Telephone"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('telephone')"
      :enableValidation="false"
    />
    <BaseRadioButton
      name="treatmentResultOfAccident"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Was treatment received directly as a result of an accident?"
      :edit-mode="editMode"
    />

    <BaseRadioButton
      name="privatePatient"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Did you elect to be a private patient of the consultant?"
      :edit-mode="editMode"
    />
  </div>
</template>
